import type { CategoryList } from '~/stores/useCategoryStore/categoryStore'

type CategoryListResponse = { data: { categoryList: CategoryList } }

export const getCategoryListCommand = {
  execute: async (params): Promise<CategoryList> => {
    const localePath = useLocalePath()
    const {
      $i18n: { locale },
    } = useNuxtApp()

    const { data } = <CategoryListResponse>await $fetch('/api/magento/categoryList', {
      method: 'POST',
      body: {
        key: `categoryList_${params.parent_id.in}_${locale.value}`,
        queryVariables: { filters: params },
        query: { categoryList: 'category-list' },
        locale: locale.value,
      },
    })

    return (data?.categoryList?.map((category) => ({
      ...category,
      url: localePath(`/${category.url_path}${category.url_suffix}`),
    })) || []) as CategoryList
  },
}
