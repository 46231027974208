import type { Locale } from '~/types/locale'

type Query = {
  query: {
    path: string
    locale: Locale
  }
}
export const getPages = {
  execute: async (query: Query) => $fetch('/api/bloomreach/pages', query),
}
