// Filename starts with "z" to ensure it runs after all other plugins are loaded
export default defineNuxtPlugin(async (nuxtApp) => {
  usePageStore()
  const { load: loadUser } = useUser()
  const { getSegmentations } = useExponeaStore()
  const { setAbTests } = useAbTestStore()
  const productStore = useProductStore(nuxtApp.pinia)
  const { addToWishlistAfterLogin } = useWishlistStore()
  //set cookies
  const { $i18n } = useNuxtApp()
  const { init: initGTM } = useGTMComposable()
  const storeCookie = useCookie('vsf-store')
  const localeCookie = useCookie('vsf-locale')
  const countryCookie = useCookie('vsf-country')
  const currencyCookie = useCookie('vsf-currency')

  // Load server side
  await callOnce('getAttributes', () => productStore.getAttributes())

  // Loads on "mounted", so only on client side
  const setIntialState = async () => {
    initGTM()
    setAbTests()
    await loadUser()
    getSegmentations()
    addToWishlistAfterLogin()

    storeCookie.value = `con_${$i18n.locale.value.substring(0, 2)}`
    localeCookie.value = $i18n.locale.value
    countryCookie.value = $i18n.locale.value
    currencyCookie.value = $i18n.localeProperties.value.defaultCurrency
  }
  // app:beforeMounted generates lots of hydration errors because of loadUser
  // so we use app:mounted
  nuxtApp.hook('app:mounted', () => setIntialState())
})
