import type { PageModel } from '@bloomreach/spa-sdk'

type Usp = {
  icon: string
  message: string
}

export const usePCPStore = defineStore('pcp', () => {
  const logger = useAppLogger('usePCPStore')
  const {
    $i18n: { locale },
  } = useNuxtApp()
  const { breadcrumbs } = storeToRefs(useBreadcrumbsStore())

  const state = reactive({
    usps: <Record<string, Usp[]>>{},
    currentUsps: <Usp[]>[],
    loading: <boolean>false,
  })

  const fetchBloomreachPage = async (relativePageUrl: string): Promise<PageModel | undefined> => {
    try {
      return await $fetch('/api/bloomreach/pages', {
        query: {
          path: relativePageUrl,
          locale: locale.value,
        },
      })
    } catch (error) {
      logger.warn(`Failed to get bloomreach page: ${relativePageUrl}`, error)
    }
  }

  const mapDataToUsps = (data: any): Usp[] => {
    const messages = Object.keys(data)
      .filter((key) => key.includes('message'))
      .sort(sortStrings)
      .map((key) => data[key])

    const icons = Object.keys(data)
      .filter((key) => key.includes('icon'))
      .sort(sortStrings)
      .map((key) => data[key])

    return messages.map((message, i) => ({
      message,
      icon: icons[i] ?? '',
    }))
  }

  const getUSP = async (searchTerm: CategoryId): Promise<Usp[] | void> => {
    const pageData = await fetchBloomreachPage(`/pcp?q=${searchTerm}&u=${searchTerm}`)
    if (!pageData?.page) return

    const data =
      Object.values(pageData.page).find((object) => object?.data?.contentType === 'brxsaas:mjPLPUSP')?.data || {}

    state.usps[searchTerm] = mapDataToUsps(data)

    return state.usps[searchTerm]
  }

  const getGlobalUsps = async () => {
    try {
      const data: BrDocumentResponse = await $fetch('/api/bloomreach/document', {
        query: {
          path: '/pcpusp/global',
          locale: locale.value,
        },
      })

      state.usps['global'] = mapDataToUsps(data?.content[data?.document?.$ref.substring(9)].data)
    } catch (error) {
      logger.warn('Failed to global usps', error)
    }
  }

  const loadPcpUsps = async () => {
    const categoryIds = breadcrumbs.value
      ?.filter((breadcrumb) => breadcrumb.id)
      .map((breadcrumb) => breadcrumb.id)
      .reverse()
    if (state.loading) return

    state.loading = true

    await getGlobalUsps()

    await Promise.all(
      categoryIds.map(async (categoryId) => {
        if (!state.usps?.[categoryId]) {
          const categoryUsps = await getUSP(categoryId)
          if (!categoryUsps) return
          state.usps = {
            ...state.usps,
            [categoryId]: categoryUsps,
          }
        }
      }),
    )

    // Get first available USPs, fallback on global
    categoryIds.push('global')
    state.currentUsps = categoryIds.reduce((acc, categoryId) => {
      if (!acc.length && state.usps[categoryId]) {
        acc = state.usps[categoryId]
      }
      return acc
    }, [])

    state.loading = false
  }

  return {
    ...toRefs(state),
    loadPcpUsps,
  }
})

export default usePCPStore
