import type { Cart, CartItemInterface, RemoveItemFromCartInput } from '@vue-storefront/magento-types'
import { updateItemQtyCommand } from './updateItemQtyCommand'
import { getGiftcardHolders } from '~/composables/useCart/utils/giftcardHoldersUtils'

export const removeItemCommand = {
  removeGiftCardHolders: (quantity = 1) => {
    const cartStore = useCartStore()

    const giftCardHolders = getGiftcardHolders(cartStore.cart.items)

    if (giftCardHolders?.length === 1) {
      if (giftCardHolders[0]?.quantity === 1) {
        removeItemCommand.execute({ product: giftCardHolders[0] })
      } else {
        updateItemQtyCommand.execute({
          product: giftCardHolders[0],
          quantity: giftCardHolders[0]?.quantity! - quantity,
        })
      }
    }
  },
  execute: async ({ product }: { product: CartItemInterface }) => {
    const { $sdk } = useNuxtApp()
    const { cart, setCart } = useCartStore()

    console.debug('[Magento]: Remove item from cart', {
      product,
      currentCart: cart,
    })

    const item = cart?.items?.find((cartItem) => cartItem!.uid === product.uid)

    if (!item) return

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: cart.id!,
      cart_item_uid: item.uid,
    }

    const { data, errors } = await $sdk.magento.removeItemFromCart(removeItemParams, {
      removeItemFromCart: 'remove-item-from-cart',
    })

    if (item.product.sku === 'mjgiftcard' && !errors?.length) removeItemCommand.removeGiftCardHolders(item.quantity)

    setCart(data?.removeItemFromCart?.cart as Cart)

    console.debug('[Result]:', { data, errors })

    if (!data?.removeItemFromCart && errors?.length) {
      throw errors[0]
    }

    // eslint-disable-next-line consistent-return
    return data?.removeItemFromCart?.cart as Cart
  },
}
