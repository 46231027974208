import { useUiNotification } from '~/composables'
import { cartCookieName, customerCookieName } from '~/enums/cookieNameEnum'

export const handleCartError = (errorLocation: string, errors: Record<string, any>[], showMessageToUser: boolean) => {
  const { send: sendNotification } = useUiNotification()
  const cartStore = useCartStore()
  const logger = useAppLogger('handleCartError')
  const {
    $i18n: { t },
  } = useNuxtApp()
  const cartCookie = useCookie(cartCookieName)
  const customerCookie = useCookie(customerCookieName)

  const errorCode = errors?.[0]?.extensions?.error_code || JSON.stringify(errors)

  logger.error(errorLocation, { cart_id: cartCookie.value, errorCode })

  if (showMessageToUser) {
    sendNotification({
      id: Symbol('product_add_error'),
      message: t(errorCode?.toString()),
      type: 'danger',
      icon: 'danger',
      persist: false,
    })
  }

  if (errorCode === 'CART_NOT_FOUND' || errorCode === 'CART_NOT_ACTIVE' || errorCode === 'CART_AUTHORIZATION_FAILED') {
    cartCookie.value = null
    // Don't recreate the cart immediately. Might infinite loop.

    // Anonymous user will lose their cart if the cookie is invalid, so we clear it
    if (!customerCookie.value) {
      cartStore.cart = null
    }
  }
}
