import { parse } from 'node-html-parser'

export const TransformationTypes = Object.freeze({
  FIT: Symbol('fit'),
  FILL: Symbol('fill'),
})
/**
 * @param str Raw string containing Bynder JSON
 * @param size Currently able to set to 'mini', '', 'webImage'. Defaults to webImage / selectedFile
 * @param index First item in JSON array
 * @returns Image url as string
 */
export const getBynderImageUrl = (str: string, size: string | null = null, index = 0) => {
  try {
    // Try to parse as JSON string and return first image.
    const bynderImage = JSON.parse(str)
    if (bynderImage.length) {
      if (size && bynderImage[index]?.files[size]) return bynderImage[index]?.files[size]?.url
      return bynderImage[index]?.type === 'VIDEO'
        ? bynderImage[index]?.files?.webImage?.url /* WebImage is the highest available quality besides original */
        : bynderImage[index]?.selectedFile?.url
    }
  } catch (e) {
    // Fallback to string url (old Bloomreach documents).
    return str
  }
}

export const getBynderImageName = (str: string, index = 0) => {
  try {
    const bynderImage = JSON.parse(str)
    if (bynderImage.length) {
      return bynderImage[index]?.name
    }
  } catch (e) {
    return ''
  }
}

export const getBynderImageObject = (str: string, size: string | null = null, index = 0) => {
  try {
    const bynderImage = JSON.parse(str)
    if (bynderImage.length) {
      if (size && bynderImage[index]?.files[size]) return bynderImage[index]?.files[size]
      return bynderImage[index]?.selectedFile
    }
  } catch (e) {
    return null
  }
}

export type BynderAsset = {
  type: string
  title: string
  thumbnailUrl: string
  srcsetDesktop?: string
  srcsetMobile?: string
  sizes?: string
  url?: string
}

export const getBynderAsset = (
  str?: string,
  strMobile?: string,
  sizes: string = '100vw',
  ratio?: number,
  ratioMobile?: number,
  transformation: symbol = TransformationTypes.FILL,
): BynderAsset | string => {
  try {
    const dataDesktop = str ? JSON.parse(str)?.[0] : null
    const dataMobile = strMobile ? JSON.parse(strMobile)?.[0] : null

    let resultAsset: BynderAsset
    if (dataDesktop) {
      resultAsset = {
        type: dataDesktop.type,
        title: dataDesktop.name,
        thumbnailUrl: dataDesktop.files?.webImage?.url,
        sizes,
      }

      let desktopExtension: string = dataDesktop.files?.original?.url?.split('.').pop() || 'jpg'
      // Video
      if (dataDesktop.type === 'VIDEO') {
        resultAsset.url = dataDesktop.selectedFile?.url
      }

      if (desktopExtension === 'svg') {
        resultAsset.thumbnailUrl = dataDesktop.files.original.url
      } else {
        resultAsset.srcsetDesktop = getSourceSet(dataDesktop, sizes, ratio, transformation)
      }

      // Override fallback url (old devices)
      resultAsset.thumbnailUrl = `${getTransformBaseUrl(dataDesktop)}?io=transform:${
        transformation.description
      },width:2048`
    }

    if (dataMobile) {
      let mobileExtension: string = dataMobile.files?.original?.url?.split('.').pop() || 'jpg'
      if (mobileExtension === 'svg') {
        resultAsset.thumbnailUrl = dataMobile.files.original.url
      } else if (dataMobile.__typename === 'Video') {
        resultAsset.urlMobile = dataMobile.selectedFile?.url
      } else {
        resultAsset.srcsetMobile = getSourceSet(dataMobile, sizes, ratioMobile, transformation)
      }
    }

    return resultAsset
  } catch (e) {
    // console.error('Fallback bynderImage', e)
    // Fallback to string url (old Bloomreach documents).
    return str
  }
}

const getSourceSet = (data: object, sizes: string, ratio: number, transformation: symbol): string => {
  let result: string
  const transformBaseUrl = getTransformBaseUrl(data)
  // If no size is given, 100vw is assumed and we use these default sizes
  const srssetSizes = new Set<number>([375, 750, 1024, 1440, 2048, 2880])

  // Don't generate default sizes if not needed
  if (!sizes.includes('vw')) {
    srssetSizes.clear()
  }

  // Generate fitting sizes based on sizes parameter (@1x @2x @3x)
  const customSizes = sizes.split(',')
  customSizes.forEach((sizeQuery) => {
    // Remove mediaqueries
    const size = sizeQuery.replace(/(.*)\s/, '')

    // We use default sizes for everything except PX
    if (!size.includes('px')) return

    const widthInPx = parseInt(size.replace('px', ''))

    for (let i = 1; i < 4; i++) {
      srssetSizes.add(widthInPx * i)
    }
  })

  // Generate string with transforms
  for (const width of srssetSizes) {
    let baseString = `${transformBaseUrl}?io=transform:${transformation.description},width:${width}`

    if (ratio) {
      baseString += `,height:${Math.ceil(width * ratio)}`
    }

    const url = `${baseString} ${width}w`
    if (!result) {
      result = url
      continue
    }

    result += `, ${baseString} ${width}w`
  }
  return result
}

const getTransformBaseUrl = (data): string => {
  let transformBaseUrl = data.files.transformBaseUrl?.url

  // If the transformBaseUrl is not provided, construct it. This is really sketchy.
  if (!transformBaseUrl) {
    const imageId = (data.databaseId.substring(0, 23) + '-' + data.databaseId.substring(23)).toLowerCase()
    const imageName = data.name.replaceAll(' ', '-')
    transformBaseUrl = `https://art.my-jewellery.com/transform/${imageId}/${imageName}`
  }
  return transformBaseUrl
}

export enum AspectRatioNumber {
  square = 1,
  portrait = 1.778,
  landscape = 0.5625,
  original = 0,
}

export enum AspectRatio {
  Square = 'square',
  Portrait = 'portrait',
  Landscape = 'landscape',
  Original = 'original',
}

export const parseBynderImagesInHtml = (html, ratio: AspectRatio = AspectRatio.Original) => {
  const root = parse(html)

  const ratioNum = AspectRatioNumber[ratio]

  // if (ratio === AspectRatio.Original) {
  //   return html;
  // }

  root.querySelectorAll('img[data-btype="bynder"]').forEach((img) => {
    const alt = img.getAttribute('alt') ?? ''
    const src = img.getAttribute('src') ?? ''

    img.setAttribute('loading', 'lazy')

    if (!img.getAttribute('data-bid')) {
      return img
    }

    /* Already transformed image detected */
    if (img.getAttribute('src')?.indexOf('?io=')) {
      return img
    }

    const bynderId = img.getAttribute('data-bid')
    const imageId = (bynderId.substring(0, 23) + '-' + bynderId.substring(23)).toLowerCase()
    const imageName = src.substring(src.lastIndexOf('/') + 1)
    const imageSrc = `https://art.my-jewellery.com/transform/${imageId}/${imageName}`

    const str = JSON.stringify([
      {
        type: 'IMAGE',
        databaseId: bynderId,
        files: {
          original: {
            url: imageSrc,
            width: 610,
            height: 610,
          },
          transformBaseUrl: {
            url: imageSrc,
          },
        },
        name: alt,
        sizes: `(max-width: 1023px) 100vw, ${ratio === AspectRatio.Portrait ? '432px' : '610px'}`,
      },
    ])
    const asset = getBynderAsset(
      str,
      null,
      `(max-width: 1023px) 100vw, ${ratio === AspectRatio.Portrait ? '432px' : '610px'}`,
      ratioNum,
    ) as BynderAsset

    const picture = `
      <picture class="bynder-asset bynder-asset--image bynder-asset--type-${ratio.toLowerCase()}">
        <source media="(max-width: 1024px)" srcset="${asset.srcsetMobile}" />
        <source media="${asset.srcsetMobile ? 'min-width: 1025px' : null}" srcset="${asset.srcsetDesktop}" />
        <img
        alt="${alt}"
        src="${src}"
        height="610"
        width="610"
        loading="lazy"
      />
      </picture>
    `

    img.parentNode.innerHTML = picture
  })

  return root.toString()
}

export const getSvgOrThumbnailImageUrl = (image) => {
  try {
    const o = JSON.parse(image)?.[0] || null
    if (o?.extensions?.[0] === 'svg') {
      return o?.files?.['original']?.url
    }

    let transformBaseUrl = ''

    if (o?.files?.transformBaseUrl) {
      transformBaseUrl = o.files.transformBaseUrl?.url
    } else {
      const imageId = (o?.databaseId?.substring(0, 23) + '-' + o?.databaseId?.substring(23)).toLowerCase()
      transformBaseUrl = `https://art.my-jewellery.com/transform/${imageId}`
    }

    const w = getCalculatedWidth(image) || 40
    return `${transformBaseUrl}?io=transform:fill,width:${w * 2}`
  } catch {
    return ''
  }
}

export const getCalculatedWidth = (image) => {
  try {
    const imageObj = JSON.parse(image)
    const w = imageObj?.[0]?.files?.['original']?.width || 45
    const h = imageObj?.[0]?.files?.['original']?.height || 28
    if (w === h) return 28
    else {
      const ratio = w / h
      if (ratio > 1.9) return Math.floor((w / h) * 18)
      else return Math.floor((w / h) * 25)
    }
  } catch {
    return 45
  }
}

export default getBynderImageUrl
