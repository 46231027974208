import type { Cart } from '@vue-storefront/magento-types'
import { customerCookieName, cartCookieName } from '~/enums/cookieNameEnum'
import { handleCartError } from '~/composables/useCart/utils/handleCartError'

export const loadCartCommand = {
  execute: async (
    params: { realCart: boolean; completeCart: boolean } = {
      realCart: false,
      completeCart: false,
    },
  ) => {
    console.debug('[Magento Storefront]: Loading Cart')
    const { $sdk } = useNuxtApp()
    const customerToken = useCookie(customerCookieName)
    const cartCookie = useCookie(cartCookieName)
    const virtual = !params.realCart
    const createVirtualCart = () => null as unknown as Cart

    /**
     * Synchronizes the Storefront cart with the Magento cart.
     *
     * @returns {Promise<string|null|undefined>} A Promise that resolves when the synchronization is complete.
     * @throws Will throw an error if the response from the Magento backend is not OK (status code is not 200-299).
     */

    const createRealCart = async (): Promise<string | null | undefined> => {
      console.debug('[Magento Storefront]: useCart.load.createNewCart')

      cartCookie.value = null

      const { data } = await $sdk.magento.createEmptyCart()
      console.debug('[Result]:', { data })

      cartCookie.value = data?.createEmptyCart

      return data?.createEmptyCart
    }

    const getCartData = async () => {
      const id = cartCookie.value ? String(cartCookie.value) : ''
      console.debug('[Magento Storefront]: useCart.load.getCartData ID->', id)

      const realId = id.indexOf('|') ? <string>id.split('|').pop() : id
      const { data, errors } = await $sdk.magento.cart(realId, { cart: 'get-cart' })
      console.debug('[Result]:', { data })

      const cart = data?.cart!

      if (!cart && errors) {
        throw errors
      }

      cart.items = cart?.items?.filter(Boolean)
      return cart
    }

    const getCart = async (virtualCart: boolean) => {
      if (!cartCookie.value) {
        if (virtualCart) {
          return createVirtualCart()
        }

        cartCookie.value = await createRealCart()
        return
      }
      return getCartData()
    }

    // Try to load cart for existing customer, clean customer token if not possible
    if (customerToken.value) {
      try {
        // If completeCart is requested do custom query
        const { data, errors } = params.completeCart
          ? await $sdk.magento.cart('', { cart: 'customer-cart-complete' })
          : await $sdk.magento.cart('', { cart: 'customer-cart' })

        console.debug('[Result]:', { data, errors })

        if (!data?.customerCart && errors?.length) {
          throw errors[0]
        }

        cartCookie.value = data.customerCart.id
        data.customerCart.items = data.customerCart.items.filter(Boolean)

        return data.customerCart as unknown as Cart
      } catch {
        customerToken.value = null
      }
    }

    try {
      // If it's not existing customer check if cart id is set and try to load it
      return await getCart(virtual)
    } catch (err) {
      handleCartError('loadCartCommand/getCart', err, false)
      return await getCart(virtual)
    }
  },
}
