import type { Product } from '~/types/product'
import type { WishlistIds } from '~/stores/useWishlistStore/wishlistStore'

export const findItemOnWishlist = (currentWishlist: WishlistIds, product: Product) => {
  const wishlist: WishlistIds = Array.isArray(currentWishlist) ? currentWishlist[0] : currentWishlist

  const productMatch = (productA: Product, productB: Product): boolean => {
    // Only match SKU against MJxxxx-yyyy (NOT: MJxxxx-yyyy-zz)
    const productASku = productA?.sku?.split('-').slice(0, 2).join('-')
    const productBSku = productB?.sku?.split('-').slice(0, 2).join('-')

    return productASku === productBSku
  }

  return wishlist?.items_v2?.items?.find((item) => productMatch(item?.product as Product, product))
}
