export const range = (start: number, stop: number, step: number) => {
  let a = [start]
  while (start < stop) {
    start += step || 1
    a.push(start)
  }
  return a
}

export const sortStrings = (a: string, b: string) => (a ?? '').localeCompare(b ?? '')
