import { messageCookieName } from '~/enums/cookieNameEnum'

export interface UiNotification {
  message: string
  title?: string
  action?: { text: string; onClick: (...args: any) => void }
  type: 'danger' | 'success' | 'info'
  icon: string
  persist?: boolean
  link?: string
  linkText?: string
  id: symbol
  dismiss?: () => void
  timeToLive?: number
}

export interface Notifications {
  notifications: Array<UiNotification>
}

const state = reactive<Notifications>({
  notifications: [],
})
const maxVisibleNotifications = 3

export const useUiNotification = (): {
  send: (notification: UiNotification) => void
  notifications: Ref<Array<UiNotification>>
} => {
  const messageCookie = useCookie(messageCookieName)
  const cookieMessage = messageCookie.value as unknown as UiNotification

  const send = (notification: UiNotification) => {
    const id = Symbol('id')

    const dismiss = () => {
      const index = state.notifications.findIndex((n) => n.id === id)

      if (index !== -1) state.notifications.splice(index, 1)

      messageCookie.value = null
    }

    const newNotification = {
      ...notification,
      id,
      dismiss,
    }

    if (!state.notifications.some((stateNotification) => stateNotification.message === notification.message)) {
      state.notifications.push(newNotification)
    }

    if (state.notifications.length > maxVisibleNotifications) {
      state.notifications.shift()
    }

    if (!notification.persist) {
      setTimeout(dismiss, notification.timeToLive || 3000)
    }
  }

  if (cookieMessage) {
    send(cookieMessage)
  }

  return {
    send,
    notifications: computed(() => state.notifications),
  }
}

export default useUiNotification
