export default defineNuxtPlugin(() => {
  window.levenshteinDistance = (a, b) => {
    if (a.length === 0) return b.length
    if (b.length === 0) return a.length

    let matrix = []

    // increment along the first column of each row
    let i
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i]
    }

    // increment each column in the first row
    let j
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) == a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1]
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1,
            ),
          ) // deletion
        }
      }
    }

    return matrix[b.length][a.length]
  }

  // Function to verify the email provided, which compares to the provided list of known domains
  window.verifyEmail = (email) => {
    let score = Infinity
    let suggestion = ''
    let domain = email.substr(email.indexOf('@') + 1)
    let fullDoamin = email
    let list = []
    let student = false

    //   Check if mail is a student mail, else check email domain
    if (domain.substr(domain.indexOf('.') + 1).indexOf('.') > 0) {
      fullDomain = domain
      domain = domain.substr(0, domain.indexOf('.'))
      list = ['student', 'leerling']
      student = true
    } else {
      list = [
        'hotmail.com',
        'hotmail.de',
        'gmail.com',
        'live.nl',
        'live.de',
        'outlook.com',
        'outlook.de',
        'hotmail.nl',
        'icloud.com',
        'icloud.de',
        'ziggo.nl',
        'home.nl',
        'msn.com',
        'msn.de',
        'kpnmail.nl',
        'upcmail.nl',
        'yahoo.com',
        'hetnet.nl',
        'planet.nl',
        'me.com',
        'telenet.be',
        'casema.nl',
        'xs4all.nl',
        'zeelandnet.nl',
        'kpnplanet.nl',
        'chello.nl',
        'quicknet.nl',
        'hotmail.be',
        'live.com',
        'live.be',
        'online.nl',
        'zonnet.nl',
        'telfort.nl',
        'solcon.nl',
        'caiway.nl',
        'skynet.be',
        'outlook.be',
        'tele2.nl',
        'mail.com',
        'onsbrabantnet.nl',
        'yahoo.nl',
        'versatel.nl',
        'gmx.de',
      ]
    }

    for (const element of list) {
      let lD = window.levenshteinDistance(domain, element)
      if (lD < score) {
        score = window.levenshteinDistance(domain, element)
        suggestion = '@' + element
      }
    }
    if (student) {
      suggestion = suggestion + fullDoamin.substring(fullDoamin.indexOf('.'))
    }
    return { score: score, suggestion: suggestion, student: student }
    //return score === 0 || (score > 4 && score !== Infinity);
  }

  window.checkEmail = () => {
    let emailField = document.getElementById('emailField')
    let emailValue = verifyEmail(emailField.value)
    let suggestion = document.getElementById('emailDomain')

    if (emailValue.score < 4 && emailValue.score !== 0) {
      // email is not verified
      document.getElementsByClassName('form-input__email')[0].classList.add('form-input__email--error')
      suggestion.innerHTML = emailValue.suggestion
      suggestion.onclick = () => {
        emailField.value = emailField.value.substring(0, emailField.value.indexOf('@')) + emailValue.suggestion
        checkEmail()
      }
    } else {
      document.getElementsByClassName('form-input__email')[0].classList.remove('form-input__email--error')
      suggestion.innerHTML = ''
      suggestion.onclick = () => {
        return false
      }
    }
  }

  /*
Check if the needed element has been loaded

checkElement("#example")
.then((element) => {
	// Is returned as DOM-element
	doStuffWithElement(element);
})
.catch((error) => {
	// Throws error to top try/catch
	throw error; 
});
*/

  window.checkElement = async (selector) => {
    while (document.querySelector(selector) === null) {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }
    return document.querySelector(selector)
  }

  window.checkJquery = async () => {
    while (typeof jQuery !== 'function') {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }
    return true
  }

  window.checkSlick = async () => {
    while (typeof jQuery().slick !== 'function') {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }
    return true
  }

  window.checkHotjar = async () => {
    while (typeof hj !== 'function') {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }
    return true
  }

  // Pass function as string
  window.checkFunction = async (func) => {
    while (typeof window[func] !== 'function') {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }
    return true
  }

  window.searchObject = (valueKey, nameKey, myArray) => {
    for (const element of myArray) {
      if (element[valueKey] === nameKey) {
        return element
      }
    }
  }

  // Get the eventproperties for Exponea
  window.getEventProperties = (data, action, interactive, typeOfClick) => {
    return {
      action: action,
      banner_id: data.experiment_id || data.banner_id,
      banner_name: data.experiment_name || data.banner_name,
      banner_type: data.experiment_type || data.banner_type,
      variant_id: data.variant_id,
      variant_name: data.variant_name,
      interaction: interactive !== false,
      location: window.location.href,
      path: window.location.pathname,
      variant_click: typeOfClick,
    }
  }
})
