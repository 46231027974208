/**
 * Gets the facets (pretty urls) from Bloomreach CMS these should match exactly (case sensitive) with the facets from Bloomreach Discovery.
 * @returns {Promise<PrettyUrlFilter[]>} The facets matched with the pretty URL filters.
 * @throws {Error} If failed to fetch the pretty URL facets.
 */

export default async () => {
  const logger = useAppLogger('loadPrettyUrlFilters')
  const {
    $i18n: { locale },
  } = useNuxtApp()
  const { prettyUrls } = storeToRefs(usePrettyUrlStore())

  if (prettyUrls.value.length > 0) {
    logger.info(`[loadPrettyUrlFilters] Already loaded ${prettyUrls.value.length} facets for ${locale.value}`)
    return
  }

  try {
    const data = await $fetch('/api/bloomreach/document', {
      query: {
        path: '/facets/facets',
        locale: locale.value,
      },
    })

    // Get the facet $refs from 'prettyUrlItems'
    const prettyUrlRefs =
      data?.content[data?.document?.$ref.substring(9)].data?.prettyUrlItems?.map((item) =>
        item.$ref.split('/').pop(),
      ) || []

    // Get the facets object by reference and map to useful values
    const facets = prettyUrlRefs.map((ref) => {
      const facet = data?.content?.[ref]?.data
      // Facet type and value are in one string: "key=value" (e.g. "colors=black")
      const facetTypeAndValue = facet.facetValue?.split('=')

      return <PrettyUrlFilter>{
        label: facetTypeAndValue?.[1] || facet.facetValue,
        slug: facet.prettyUrl,
        type: facetTypeAndValue?.[0] || 'default',
      }
    })

    if (facets?.length < 1) {
      throw new Error('[loadPrettyUrlFilters] No facets found')
    }

    logger.info(`[loadPrettyUrlFilters] Got ${facets.length} facets for ${locale.value}`)

    prettyUrls.value = facets
  } catch (err) {
    throw new Error('Failed to fetch pretty url facets')
  }
}
