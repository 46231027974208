import customAttributeMetadataQuery from '~/magentoMiddleware/customQueries/getCustomAttributeMetadata.gql'
import type { Attribute } from '../useProductStore'
import type { CustomQueryResponse } from '@vue-storefront/magento-sdk'

export const getAttributeData = async () => {
  const logger = useAppLogger('getAttributeData')
  const {
    $i18n: { locale },
  } = useNuxtApp()

  try {
    const { data } = <CustomQueryResponse<any>>await $fetch('/api/magento/customQuery', {
      method: 'POST',
      body: {
        key: `custom_attribute_metadata_${locale.value}`,
        query: customAttributeMetadataQuery,
        locale: locale.value,
      },
    })

    logger.debug(`useCustomAttributeMetadata/search/result`, data.customAttributeMetadata)

    return data.customAttributeMetadata?.items as Attribute[]
  } catch (err) {
    logger.error(`useCustomAttributeMetadata/search/error`, err)
    return undefined
  }
}
